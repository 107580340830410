.courseInfoDiv {
  max-width:500px;
  margin: 20px auto;
  padding: 10px;
  color:white;
}

.title {
  font-weight:bold;
  font-size: 26px;
}

.separator {
  display:block;
  width:90px;
  height:10px;
  background-color:#ffff00;
  margin: 16px 0 16px;
}

.text {
  font-size:20px;
  margin-bottom: 16px
}
