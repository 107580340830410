.section {
  background-color: white;
  padding-top: 64px;
}

.div {
  max-width:500px;
  margin: 0 auto;
  padding:10px;
}

.text {
  font-size:18px;
  padding-bottom: 8px;
}

.logo {
  display:inline-block;
  padding-bottom: 16px;
}

.logo img {
  width: 100px;
}

.socialSection {
  padding-top: 32px;
}

.social {
  padding: 64px 20px 0 0;
}
