.container {
  color: #353546;
  background-color:white;
  padding-bottom: 64px;
}

.professorDiv {
  max-width:500px;
  margin:0 auto;
  padding: 10px;
}

.title {
  font-size:26px;
  text-transform: uppercase;
  margin: 32px 0 16px;
  font-weight: 700;
}

.span {
  width:90px;
  height:10px;
  background-color: #fff000;
  display:block;
}

.img {
  display:block;
  width:330px;
  max-width:100%;
  margin:16px auto;
  border: 10px solid #fff000;
  border-radius: 50%;
  padding: 10px;
}

.spanName {
  text-align:center;
  margin: 32px auto 0 auto;
  background:#fff000;
  width:330px;
  height:64px;
  max-width:100%;
}

.name {
  font-size:20px;
  text-transform: uppercase;
  vertical-align:middle;
  text-align: center;
  display:inline-block;
}

.text {

  margin-top:32px;
  line-height:25px;
  letter-spacing:.1em;
}
