body, p, h1, ul, li, blockquote {
  padding: 0px;
  margin: 0px;
}

body {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}
