

.video {
  max-width:500px;
  margin: 0 auto;
  padding: 10px;
}

.videoDiv {
  margin: auto;
}

.embed {
  margin:0 auto;
  width: 100%;
  max-width:500px;
  height:300px;

}

.objectiveTime {
  text-align:center;
  font-size:22px;
  text-transform:uppercase;
  color: #ffff00;
}


.videoText {
  font-size: 20px;
  max-width: 500px;
  padding:8px 10px 0 10px;
  color: white;
}

.list {
  margin-top: 32px;
  padding: 0;
  color: white;
  font-size:30px;
  text-transform:uppercase;
  font-weight: bold;
  list-style-type: none;
  text-align:center;
}

.list li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: 10px;
  background-color: black;
}

.formText {
  margin-top: 32px;
  font-size: 22px;
  text-align: center;
  color:#ffff00;
  line-height: 30px;
  /* font-weight: 700; */
}
