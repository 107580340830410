.card{
max-width:500px;
background-color:#353546;
padding:10px;
margin-top:32px;
color: white;
}

.main {
  display:flex;
  align-items:center;
}

.span {
  background-color: #fff000;
  vertical-align:middle;
  width: 50px;
  height:50px;
  border-radius: 50%;
  vertical-align:middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #353546;
}

.number {
  font-size: 22px;
  font-weight:bold;
}

.titleContainer {
  max-width: 240px;
  margin-left:16px;
}

.title {
  margin: 0;
  margin-bottom: 0;
  font-size:14px;
  font-weight:bold;
  text-transform: uppercase;
}

.date {
  font-size:16px;
  margin-top:5px;
}

.cardOdd{
max-width:500px;
background-color:#fff000;
padding:10px;
margin-top:32px;
color: #353546;
}

.spanOdd {
  background-color:#353546;
  vertical-align:middle;
  width: 50px;
  height:50px;
  border-radius: 50%;
  vertical-align:middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff000;
}

.text {
  font-size:14px;
  margin-top:0px;
  list-style-type:none;
  padding-left: 10px;
}

.text li {
  padding-top: 5px;
}
