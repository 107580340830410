.header {
  margin: 20px auto;
  max-width: 500px;
  text-align:center;
}

.header img {
  width:300px;
  margin:0;
}

.data {
  margin-top:0;
  font-size:22px;
  color: #ffff00;
  text-transform: uppercase;
}

.objective {
  margin:0 auto;
  padding: 5px 0 5px 0;
  font-size:22px;
  color: white;
  text-transform:uppercase;
  letter-spacing: 2px;
  line-height: 130%;
  font-weight: 500;
  max-width: 480px;
}

