.form {
  max-width:500px;
  color: white;
  margin: 0 auto;
  padding: 32px 10px 10px 10px;
}

input {
  color:rgba(0, 0, 0, 0.8);
  font-size:18px;
  font-weight:bold;

}

.name {
  font-size: 20px;
  font-weight: bold;
}

.nameInput {
  padding-left: 16px;
  width: 100%;
  height:50px;
  border-radius: 10px;
  background-color:white;
  border:none;
  margin: 15px 0 10px 0;

}

.nameInput::placeholder {

  font-size:18px;
  color:rgba(0, 0, 0, 0.4);
}


.email {
  font-size: 20px;
  font-weight: bold;

}

.emailInput {
  padding-left: 16px;
  width:100%;
  height:50px;
  border-radius: 10px;
  background-color:white;
  border:none;
  border:none;
  margin: 15px 0 10px 0;

}

.emailInput::placeholder {
  font-size:18px;
  color:rgba(0, 0, 0, 0.4);
}

.whatsapp {
  font-size: 20px;
  font-weight: bold;

}

.whatsappInput {
  padding-left: 16px;
  width:100%;
  height:50px;
  border-radius: 10px;
  background-color:white;
  border:none;
  border:none;
  margin: 15px 0 10px 0;

}

.whatsappInput::placeholder {
  font-size:18px;
  color:rgba(0, 0, 0, 0.4);
}


.formButton {
  font-size: 30px;
  font-weight: bold;
  margin-top: 42px;
  width:500px;
  max-width:100%;
  height:60px;
  border-radius:30px;
  background-color:#fff000;
  border-style: none;
}

.error {
  color: red;
}


